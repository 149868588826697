<template>
  <div class="absolute inset-0 backdrop-blur-sm z-50 ">
    <AppLoading class="mt-12">
      <template
        v-if="$slots.default"
        #default
      >
        <slot />
      </template>
    </AppLoading>
  </div>
</template>
